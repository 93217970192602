@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    td {
        @apply px-2;
    }
}

.infoCard a {
    @apply underline hover:underline hover:cursor-pointer decoration-2
    underline-offset-2 hover:decoration-pink-400 visited:text-gray-600;
}

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');
